:root {
  --g_default: #d4cfc9;
  --g_poor: rgb(212, 87, 105);
  --g_common: #e69d45;
  --g_uncommon: #57d470;
  --g_rare: #308695;
  --g_epic: #3dc8e0;
  --g_legendary: #7d3de0;
  --s_bad: #d45769;
  --s_good: #57d470;
  --s_default: #d4cfc9;
  --p_greydient: linear-gradient(180deg, #000, #1a1a1a);
  --p_color: rgba(180, 180, 180, 1);
  --p_star: #e69d45;
  --p_star_grey: rgba(255, 255, 255, 0.1);
  --i_width: calc(var(--i_height) * 68%);
  --i_height: 35vh;
  --white: rgba(255, 255, 255, 1);
  /*https://digitalsynopsis.com/wp-content/uploads/2019/04/beautiful-color-gradient-palettes-27.jpg*/
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
b {
  color: var(--white);
}

h1 {
  font-weight: 600;
  font-size: 1.2rem;
  text-transform: uppercase;
  margin: 0;
}
h2 {
  font-weight: 600;
  font-size: 1rem;
  margin: 0;
}
p {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.85rem;
  color: var(--p_color);
  margin: 0;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, WebKit */
}

body {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 12pt;
  color: var(--p_color);
  background-color: #1a1a1a;
  word-wrap: break-word;
  cursor: default;
}

.movieContainer {
  display: flex;
}

#fadeground {
  width: 100%;
  height: 100%;
  background: var(--p_greydient);
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  display: block;
}
#top_graphic {
  width: 100%;
  max-width: 800px;
  height: auto;
  margin: 0 auto;
  display: none;
}
#top_graphic img {
  width: 100%;
}
#list {
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 5% 0;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.movie {
  width: auto;
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  height: auto;
  padding: 20px;
  gap: 20px;
}
.left {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  padding: 20px 0;
  gap: 10px;
}
.poster {
  width: auto;
}
.poster img {
  width: 162px;
  height: 240px;
  border-radius: 5px;
}
.info_left {
  width: 100%;
  height: auto;
  position: relative;
  display: none;
  justify-content: flex-end;
}
.center {
  width: 100%;
  max-width: 5px;
  background: #999;
  border-radius: 5px;
}
.right {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;
  position: relative;
  gap: 10px;
}
.title {
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.release {
  width: auto;
  font-size: 10pt;
  display: none;
}
.desc_title {
  margin: 5px 0 5px 0;
}
.description {
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.additional {
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.date {
  width: auto;
}
.length {
  width: auto;
}
.info_right {
  position: absolute;
  display: block;
  bottom: 0;
  right: 20px;
}
.preview {
  width: 100%;
  margin: 5px 0;
  text-align: center;
  border-radius: 15px;
  background: rgba(50, 50, 50, 0.75);
}
.preview p {
  color: #999;
  font-size: 10pt;
  padding: 5px 0;
}
.preview img {
  width: 100%;
  height: auto;
  position: relative;
  bottom: -4px;
  border-radius: 0 0 15px 15px;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 16pt !important;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
  position: relative;
  top: 4px;
  color: var(--s_default);
}
#burger {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  background: rgba(50, 50, 50, 0.75);
  color: #999;
  display: none;
  justify-content: center;
  align-items: center;
}
#burger i {
  font-size: 24pt !important;
  top: 0 !important;
  transform: rotate(0deg);
  transition: ease-in-out 0.5s;
}

/*A2HS prompt*/
#a2hs {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  z-index: 999;
  flex-flow: column;
  justify-content: space-around;
  padding: 20px 0;
  border-top: solid 5px var(--s_default);
  background: #1a1a1a;
  align-items: center;
}
#acceptBtn {
  width: 80%;
  height: auto;
  padding: 10px 0;
  background: var(--s_good);
  border-radius: 100px;
  text-align: center;
  margin: 5px 0;
}
#declineBtn {
  width: 80%;
  height: auto;
  padding: 10px 0;
  background: var(--s_bad);
  border-radius: 100px;
  text-align: center;
  margin: 5px 0;
}

.imdb {
  display: flex;
  flex-direction: row;
  width: min-content;
}

.ratingStar {
  width: 15px;
  height: 15px;
  mask-image: url('../image/starRatingFill.svg');
  mask-repeat: no-repeat;
  mask-size: 15px;
}

@media (max-width: 800px) {
  body {
    margin: 0;
    height: 100vh;
  }

  #root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }
  #fadeground {
    display: block;
  }
  #top_graphic {
    max-width: unset;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
  }
  .movieContainer {
    justify-content: stretch;
    align-items: center;
    flex: 1;
    max-height: 100vh;
    overflow-y: hidden;
  }
  #list {
    max-width: unset;
    width: 100%;
    display: flex;
    flex-direction: row;
    scroll-snap-type: x mandatory;
    overflow-y: hidden;
    align-items: stretch;
  }
  .movie {
    flex-direction: column;
    margin: 0;
    width: 100%;
    scroll-snap-align: center;
    padding: 0;
    align-items: stretch;
    overflow-y: auto;
    max-height: 100vh;
    padding: 20px;
    box-sizing: border-box;
  }
  .poster img {
    /* width: 135px;
    height: 200px; */
    width: var(--i_width);
    height: var(--i_height);
  }
  .center {
    max-width: unset;
    width: 100%;
    min-height: 5px;
    margin: 0 auto;
  }
  .right {
    padding-bottom: 0;
  }
  .right div {
    margin: 5px 0 5px 0;
  }
  .info_left {
    display: block;
    bottom: 15px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: auto;
  }
  .info_right {
    display: none;
  }
  .title {
    margin: 0 0 5px 0 !important;
  }
  .title h1 {
    font-size: 20pt;
  }
  .additional {
    margin: 0;
  }
  .material-icons {
    line-height: 1.5pt !important;
  }
  #burger {
    display: flex;
    transform: rotate(0deg);
    transition: ease-in-out 0.5s;
  }

  body {
    margin: 0;
  }
  .hLayout #fadeground {
    display: block;
  }
  .hLayout #top_graphic {
    max-width: unset;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
  }
  .hLayout #list {
    max-width: unset;
    width: 100vw;
    display: flex;
    flex-direction: column;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    max-height: 100vh;
  }
  .hLayout .movie {
    flex-direction: row;
    margin: 0 0 15px 0;
    padding: 15px 0 0 0;
    width: 100vw;
    scroll-snap-align: start;
    min-width: 100vw;
    height: auto;
  }
  .hLayout .poster img {
    width: 75px;
    height: 111px;
  }
  .hLayout .center {
    max-width: 5px;
    width: 5px;
    height: auto;
    margin: 0 auto;
  }
  .hLayout .left {
    padding: 15px;
  }
  .hLayout .right {
    justify-content: center;
    width: 100%;
    padding: 15px;
  }
  .hLayout .right div {
    margin: 5px 0 5px 0;
  }
  .hLayout .info_left {
    display: none;
  }
  .hLayout .info_right {
    display: block;
    right: 14px;
    bottom: 5px;
  }
  .hLayout .title {
    margin: 5px 0 5px 0 !important;
  }
  .hLayout .title h1 {
    font-size: 12pt;
  }
  .hLayout .additional {
    margin: 0;
  }
  .hLayout .additional p {
    font-size: 10pt;
  }
  .hLayout .material-icons {
    line-height: 1.5pt !important;
  }
  .hLayout .description {
    display: none;
  }
  .hLayout .description p {
    font-size: 10pt !important;
  }
  .hLayout .release {
    word-break: break-all;
    display: block;
  }
  .hLayout .release p {
    font-size: 10pt;
  }
  .hLayout #burger {
    display: flex;
    transform: rotate(-90deg);
    transition: ease-in-out 0.5s;
  }
}
